import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LaddaModule } from 'angular2-ladda';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DemoMaterialModule } from './material-module';
import { AvatarModule } from 'ngx-avatar';
import { TableComponent } from './table/table.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule, ProgressbarModule, CollapseModule } from 'ngx-bootstrap';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { UploadImgB64Component } from './upload-img-b64/upload-img-b64.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    TableComponent, UploadImgB64Component ],
  imports: [
    DemoMaterialModule, AvatarModule, TranslateModule,
    LaddaModule, NgSelectModule, AlertModule.forRoot(), BsDropdownModule,
    TabsModule, ModalModule.forRoot(), CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }), ProgressbarModule, CollapseModule
    , CommonModule, ReactiveFormsModule, FormsModule
  ],
  exports: [TranslateModule, CalendarModule, UploadImgB64Component, NgSelectModule, AlertModule, BsDropdownModule,
    TabsModule, ModalModule, ProgressbarModule, CollapseModule, LaddaModule,
    TableComponent, AvatarModule, DemoMaterialModule, ReactiveFormsModule,
    FormsModule
  ]
})
export class SharedModuelsModule { }
