import { HttpClient } from "@angular/common/http";
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { LanguageService } from "../shared/services/language.service";

@Component({
    selector: "app-lang-bar",
    templateUrl: "./lang-bar.component.html",
    styleUrls: ["./lang-bar.component.scss"],
})
export class LangBarComponent implements OnInit, AfterViewInit {
    langs = [
        { langName: "العربية", langId: "8eb12a2b-4ff7-464b-b02f-2df8b2fce8c0" },
        { langName: "English", langId: "4c7d3ec0-e0e8-467d-b1ab-777c5c153644" },
    ];

    selectedLangName = "";
    isEmptyCart = true;

    Token;
    userData;
    authUrl = environment.authUrl;
    sManagerUrl = environment.sManagerUrl;
    thisAppUrl = environment.thisAppUrl;
    constructor(
        private _LanguageService: LanguageService,
        private router: Router,
        private http: HttpClient,
        private route: ActivatedRoute
    ) {
        this.route.queryParams.subscribe((par) => {
            this.Token = par["t"];
            if (this.Token) {
                this.ValidateByToken()
                    .pipe(first())
                    .subscribe((result) => {
                        this.userData = {
                            bearerToken: this.Token,
                            contactid: result.data.contactid,
                            email: result.data.email,
                            firstName: result.data.firstName,
                            isAuthorized: result.data.isAuthorized,
                            lastName: result.data.lastName,
                        };
                        localStorage.setItem(
                            "authObj",
                            JSON.stringify(this.userData)
                        );
                    });
            }            
        });
    }

    ValidateByToken() {
        return this.http.post<any>(
            `${this.sManagerUrl}/api/v1/Auth/ValidateByToken/`,
            {},
            {
                headers: { Authorization: "Bearer " + this.Token },
            }
        );
    }

    cN = "";
    ngOnInit() {
        var customerNumber = localStorage.getItem("cN");
        if (customerNumber) {
            this.cN = customerNumber;
            this.getCartDet(customerNumber)
                .pipe(first())
                .subscribe((result) => {
                    if (result.isSuccess) {
                        if (
                            result.data.cartDetails &&
                            result.data.cartDetails.length > 0
                        )
                            this.isEmptyCart = false;
                    }
                });
        }
    }

    getCartDet(customerNumber) {
        return this.http.get<any>(
            `${this.sManagerUrl}/api/v1/CustomerCart/GetCartByCustomerNumber/` +
                customerNumber
        );
    }

    onCartClicked() {
        if (this.isEmptyCart) return;

        window.location.href =
            this.authUrl + "/#/Proceed?cn=" + this.cN + "&cd=1";
    }

    ngAfterViewInit(): void {
        var currLang = localStorage.getItem("userLanguage");
        if (currLang == "8eb12a2b-4ff7-464b-b02f-2df8b2fce8c0") {
            document.getElementById("langDropDown").className =
                "langDropDownAr";
            document.getElementById("langDropDown").dir = "rtl";
            this.selectedLangName = "العربية";
        } else this.selectedLangName = "English";
    }

    changeLang(ev) {
        this._LanguageService.changeLangUsingCultureName(ev.langId);
        if (ev.langId == "8eb12a2b-4ff7-464b-b02f-2df8b2fce8c0") {
            document.getElementById("langDropDown").className =
                "langDropDownAr";
            document.getElementById("langDropDown").dir = "rtl";
            this.selectedLangName = "العربية";
        } else {
            document.getElementById("langDropDown").className = "langDropDown";
            document.getElementById("langDropDown").dir = "ltr";
            this.selectedLangName = "English";
        }
    }

    newGuidInstanceMethod() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    }

    logOut() {
        this.userData = null;
        localStorage.removeItem("authObj");
    }
}
