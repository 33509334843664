// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    // timeoutMinutes: 15,
    //    appUrl: 'http://localhost:56622/api/v1/',
    //appUrl: 'http://174.142.115.102:8000/api/v1/'
    appUrl: "https://main.api.sharewinds.tk/api/v1/",
    authUrl: "https://authdev.sharewinds.com",
    sManagerUrl: "https://smanagerdev.sharewinds.com",
    thisAppUrl:"https://commercialwebsite-dev.sharewinds.com/"
};
