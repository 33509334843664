import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import {
    HttpClient,
    HttpHeaders,
    HttpErrorResponse,
} from "@angular/common/http";
import { tap, retry, map, catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Router, ActivatedRoute } from "@angular/router";

const httpOptions = {
    headers: new HttpHeaders({
        "Content-Type": "application/json",
    }),
};

@Injectable()
export class SecurityService {
    private canonicalName: string;
    securityObject: any;
    sManagerUrl = environment.sManagerUrl;

    constructor(public router: Router, private http: HttpClient) {
        if (localStorage.getItem("canonicalName") !== null) {
            this.canonicalName = localStorage.getItem("canonicalName") + "/";
        } else {
            this.canonicalName = "";
        }
    }

    register(userdata) {
        // return this.http.post<any>(`http://xzycompany.sharewinds.com/api/subs/register`, userdata);
        return this.http.post<any>(
            `${this.sManagerUrl}/api/subs/register`,
            userdata
        );
    }

    recoverAccountName(userEmail, langName) {
        // return this.http.post<any>(`http://smanager.sharewinds.com/api/subs/recover`, userEmail);
        return this.http.post<any>(
            `${this.sManagerUrl}/api/subs/recover/${userEmail}/${langName}`,
            {}
        );
    }

    // all the below is from SW App Itself

    login(appUser) {
        this.resetSecurityObject();
        return this.http
            .post<any>(
                `${environment.appUrl}${this.canonicalName}security/sUser/login/`,
                appUser
            )
            .pipe(
                tap((resp: any) => {
                    // Use object assign to update the current object
                    // NOTE: Don't create a new AppUserAuth object
                    //       because that destroys all references to object
                    localStorage.setItem(
                        "securityObject",
                        JSON.stringify(resp.data)
                    );
                    this.securityObject = resp.data;
                })
            );
    }

    getDomain<T>(userdata): Observable<any> {
        return this.http
            .post<any>(`${this.sManagerUrl}/api/Subs/getSub/${userdata}`, {})
            .pipe(
                retry(3),
                catchError((err: HttpErrorResponse) => {
                    if (err.error instanceof Error) {
                        console.error("An error occurred:", err.error.message);
                    } else {
                        console.error(
                            `Backend returned code ${err.status}, body was: ${err.error}`
                        );
                    }
                    return throwError(err);
                })
            );
    }

    checkuserexist(userdata) {
        return this.http.post<any>(
            `${environment.appUrl}${this.canonicalName}security/sUser/check-mailorusername`,
            { email: userdata }
        );
    }

    logout(): void {
        this.resetSecurityObject();
    }
    getLookUp() {
        return this.http.get<any>(
            `${environment.appUrl}${this.canonicalName}lookup/get-menuItems`
        );
    }
    resetSecurityObject(): void {
        localStorage.setItem("userdata", null);
        localStorage.removeItem("securityObject");
        if (this.securityObject) {
            this.securityObject.userName = "";
            this.securityObject.companyCode = "";
            this.securityObject.countryId = "";
            this.securityObject.countryCode = "";
            this.securityObject.bearerToken = "";
            this.securityObject.parentUserId = "";
            this.securityObject.parentUserName = "";
            this.securityObject.isAuthenticated = false;
        }
        // this.securityObject.claims = [];
    }

    // This method can be called a couple of different ways
    // *hasClaim="'claimType'"  // Assumes claimValue is true
    // *hasClaim="'claimType:value'"  // Compares claimValue to value
    // *hasClaim="['claimType1','claimType2:value','claimType3']"

    // permationControl
    // to back permationControl remove the comment of the two function hasClaim and isClaimValid with return ret. and comment return true
    // note that permationControl can't be null

    hasClaim(claimType: any, claimValue?: any) {
        // let ret: boolean = false;
        // //console.log("login", claimType);
        // //console.log("login", claimValue);
        // // See if an array of values was passed in.
        // if (typeof claimType === "string") {
        //     ret = this.isClaimValid(claimType, claimValue);
        //     //console.log("ret", ret);
        // }
        // else {
        //     let claims: string[] = claimType;
        //     if (claims) {
        //         for (let index = 0; index < claims.length; index++) {
        //
        //             ret = this.isClaimValid(claims[index]);
        //             // If one is successful, then let them in
        //             if (ret) {
        //                 break;
        //             }
        //         }
        //     }
        // }
        //         return ret;

        return true;
    }

    private isClaimValid(claimType: string, claimValue?: string): boolean {
        // let ret: boolean = false;
        // let auth: AppUserAuth = null;

        // // Retrieve security object
        // auth = this.securityObject;
        // //console.log("auth", auth);
        // if (auth) {
        //     // See if the claim type has a value
        //     // *hasClaim="'claimType:value'"
        //     if (claimType.indexOf(":") >= 0) {
        //         let words: string[] = claimType.split(":");
        //         claimType = words[0].toLowerCase();
        //         claimValue = words[1];
        //     }
        //     else {
        //         claimType = claimType.toLowerCase();
        //         // Either get the claim value, or assume 'true'
        //         claimValue = claimValue ? claimValue : "true";
        //     }
        //     // Attempt to find the claim

        //     ret = auth.claims.find(c =>
        //         c.permissionControl.toLowerCase() == claimType &&
        //         c.permissionValue.toString() == claimValue) != null;
        // }
        //         return ret;
        return true;
    }
}
