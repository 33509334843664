import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SecurityService } from '../services/security.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private securityService: SecurityService,
        private router: Router) {
    }
    urls: any[];
    inUrl = false;

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        return true;
        // this.securityService.securityObject = JSON.parse(localStorage.getItem('securityObject'));
        // this.urls = JSON.parse(localStorage.getItem('url'));
        // const currUrl = route.routeConfig.path.toLowerCase().split('/')[0];
        // // this.inUrl = (this.urls.indexOf(currUrl) > -1);
        // const claimType: string = route.data['claimType'];

        // if (this.securityService.securityObject
        //     // &&
        //     // this.securityService.securityObject.isAuthenticated &&
        //     // this.securityService.hasClaim(claimType)
        //     //  && this.inUrl
        // ) {
        //     return true;

        // } else {
        //     this.router.navigate(['login']);
        //     return false;
        // }
    }
}
