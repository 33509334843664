import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Result } from '../models';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private canonicalName: string;
  constructor(private translate: TranslateService, private http: HttpClient) {
   }



  private storageSub = new Subject<string>();

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  getCurrLangAbrrv(){
    var currLang = localStorage.getItem('userLanguage');
    if (currLang == '8eb12a2b-4ff7-464b-b02f-2df8b2fce8c0')
      return 'ar';    
    else if (currLang == '81efbe61-99ca-4173-8fc4-1e3f311b409a')
      return 'de';
    else
      return 'en';
   
  }

   changeLangUsingCultureName(CultureId) {
     let language = 'en';
    switch (CultureId) {
      case '81efbe61-99ca-4173-8fc4-1e3f311b409a':
        language = 'de';
        break;
      case '8eb12a2b-4ff7-464b-b02f-2df8b2fce8c0':
        language = 'ar';
        this.storageSub.next('ar');
        break;
      case '4c7d3ec0-e0e8-467d-b1ab-777c5c153644':
        language = 'en';
        this.storageSub.next('en');
        break;

    }
    localStorage.setItem('userLanguage', CultureId);
this.changeLang(language);
   }

   
changeLang(language: string) {
    const dom: any = document.querySelector('body');

        this.translate.use(language);
        if (language === 'ar') {
            dom.classList.add('rtl');
        } else {
        dom.classList.remove('rtl');
        }
        // window.location.reload();
}
changeUserLang(newLang) {
  // this.changeLang(newLang);
  let id;
  switch (newLang) {
    case 'ar':
      id = '8eb12a2b-4ff7-464b-b02f-2df8b2fce8c0';
      break;
    case 'en':
      id = '4c7d3ec0-e0e8-467d-b1ab-777c5c153644';
      break;
    case 'de':
      id = '81efbe61-99ca-4173-8fc4-1e3f311b409a';
      break;

  }

  return this.http.get<Result>(`${environment.appUrl}Security/SUser/change-culture/` + id);


}
}
