import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-landing-page",
    templateUrl: "./landing-page.component.html",
    styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit {
    constructor(private http: HttpClient, private route: ActivatedRoute) {}

    customerNumber = "";
    sManagerUrl = environment.sManagerUrl;
    authUrl = environment.authUrl;

    ngOnInit() {
        var savedCustomerNumber = localStorage.getItem("cN");
        if (!savedCustomerNumber) {
            this.RequestNewCustomerNumberWithNewCart()
                .pipe(first())
                .subscribe((result) => {
                    if (result.isSuccess) {
                        this.customerNumber = result.data.customerNumber;
                        localStorage.setItem("cN", this.customerNumber);
                    }
                });
        } else {
            this.customerNumber = savedCustomerNumber;
        }

        this.getPackagesList();
    }

    goSginup(packageId = null, productId = null) {
        window.location.href =
            this.authUrl +
            "/#/Proceed?pId=" +
            packageId +
            "&prId=" +
            productId +
            "&cn=" +
            this.customerNumber;
    }
    goLogin() {
        window.location.href = this.authUrl;
    }

    WholePackagesList = [];
    packagesList = [];
    lastSelectedPackage;
    getPackagesList() {
        this.getPackages()
            .pipe(first())
            .subscribe((result) => {
                this.WholePackagesList = result.data;
                // let currentProdId = this.signUpForm.get('productId').value;
                this.packagesList = this.WholePackagesList.filter(
                    (p) => p.productid == 1
                );
                this.lastSelectedPackage = this.packagesList[0];

                this.packagesList.forEach((element) => {
                    this.getPackageItemsList(element.packageid);
                });
                // console.log(this.PackagesItemsList);
            });
    }

    PackagesItemsList = [];
    getPackageItemsList(packageId) {
        this.getPackageItems(packageId)
            .pipe(first())
            .subscribe((result) => {
                result.data.forEach((element) => {
                    this.PackagesItemsList.push(element);
                });
            });
    }

    getPackages() {
        // return this.http.post<any>(`http://smanager.sharewinds.com/api/subs/recover`, userEmail);
        return this.http.get<any>(
            `${this.sManagerUrl}/api/v1/SystemTables/getAllPackages`
        );
    }

    getPackageItems(packageId) {
        // return this.http.post<any>(`http://smanager.sharewinds.com/api/subs/recover`, userEmail);
        return this.http.get<any>(
            `${this.sManagerUrl}/api/v1/SystemTables/getPackagseItems/` +
                packageId
        );
    }

    RequestNewCustomerNumberWithNewCart() {
        return this.http.get<any>(`${this.sManagerUrl}/api/v1/CustomerCart`);
    }
}
