import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { SecurityService, ErrorService, UsersService, LanguageService } from './shared';
import { JwtInterceptor } from './shared/helpers';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { HttpCancelService } from './shared/services/http-cancel.service';
import { ManageHttpInterceptor } from './shared/helpers/managehttp.interceptor';
import { BsDropdownModule } from 'ngx-bootstrap';
import { LangBarComponent } from './langBar/lang-bar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { SharedModuelsModule } from './shared-moduels/shared-moduels.module';
import { LandingPageComponent } from './layout/landingPage/landing-page.component';


@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LanguageTranslationModule,
        FormsModule,
        SharedModuelsModule,
        NgSelectModule,
        AppRoutingModule, BsDropdownModule.forRoot()

    ],
    declarations: [AppComponent, LangBarComponent, LandingPageComponent],
    providers: [AuthGuard, SecurityService, LanguageService, ErrorService, HttpCancelService, UsersService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        // { provide: HTTP_INTERCEPTORS, useClass: ManageHttpInterceptor, multi: true } can't navigate with this fe:resource edit page
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
