import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
    // add authorization header with jwt token if available

    const securityObject = JSON.parse(localStorage.getItem('securityObject'));

    if (securityObject && securityObject.bearerToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${securityObject.bearerToken}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            window.location.href = '/domain';
            return throwError(err);
          } else {
            return throwError(err);
          }
        }
      }));

    //   .catch(err => {
    //     // onError
    //     //console.log(err);
    //     if (err instanceof HttpErrorResponse) {
    //         //console.log(err.status);
    //         //console.log(err.statusText);
    //         if (err.status === 401) {
    //             window.location.href = "/login";
    //         }
    //     }
    //     return Observable.throw(err);
    // }) as any;
  }
}
