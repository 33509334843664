import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Result } from '../models';

@Injectable()
export class UsersService {
    private canonicalName: string;
    constructor(private http: HttpClient) {        
     }

     getListOfUsers() {
        return this.http.get<Result>(`${environment.appUrl}security/suser/get-allUsers`);
    }
   getContacts() {
        return this.http.get<Result>(`${environment.appUrl}security/suser/get-contacts`);
    }
getcontactById(contactId: string) {
    return this.http.get<Result>(`${environment.appUrl}security/suser/getcontact-byid/` + contactId);
}
createPassword(user: any) {
    return this.http.post<Result>(`${environment.appUrl}security/suser/add-contactUser/` , user);
}

addContact(contact: any) {
    return this.http.post<Result>(`${environment.appUrl}security/suser/add-Contact`, contact);
}
updateContact(contact: any) {
    return this.http.post<Result>(`${environment.appUrl}security/suser/edit-Contact`, contact);
}
getAllApplication() {
    return this.http.get<Result>(`${environment.appUrl}/security/suser/get-allUsers`);

}
getAllReports() {
    return this.http.get<Result>(`${environment.appUrl}/security/suser/get-allUsers`);

}
getPermissionTypes() {
    return this.http.get<Result>(`${environment.appUrl}/security/suser/get-permissiontypes`);
}
deleteusers(contact: any) {
    return this.http.post<Result>(`${environment.appUrl}security/suser/delete-Users`, contact);
}

_transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  }

// localization

getListOfLocalizations(masterItemId, locationname) {
    return this.http.get<Result>(`${environment.appUrl}Localization/getTranslationsforitem/${masterItemId}/${locationname}`);
}
GetLanguages() {
    return this.http.get<Result>(`${environment.appUrl}Localization/GetLanguages`);
}
GetCurrencies() {
    return this.http.get<Result>(`${environment.appUrl}Localization/GetCurrencies`);
}
GetCulturesbyclut(culture) {
    return this.http.get<Result>(`${environment.appUrl}Localization/GetCultures/` + culture);
}

GetCultures() {
    return this.http.get<Result>(`${environment.appUrl}Localization/GetCultures/`);
}
GetTranslations() {
    return this.http.get<Result>(`${environment.appUrl}Localization/GetTranslations`);
}
addLocalization(Localization) {
    return this.http.post<Result>(`${environment.appUrl}Localization/Add-Translation`, Localization);
}

addLocalizationLst(Localization) {
    return this.http.post<Result>(`${environment.appUrl}Localization/Add-TranslationLst`, Localization);
}
// send localizationIds
deleteLocalizationLst(Localization) {
    return this.http.post<Result>(`${environment.appUrl}Localization/Delete-Translations`, Localization);
}
getLocationType() {
    return this.http.get<Result>(`${environment.appUrl}Location/GetLocationType`);

}
getLocationParents() {
    return this.http.get<Result>(`${environment.appUrl}Location/GetLocationParents`);

}

}
