import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Result } from '../models';
import { environment } from '../../../environments/environment';

@Injectable()
export class ErrorService {
    private canonicalName: string;
    private messageSource = new BehaviorSubject({});
    currentMessage = this.messageSource.asObservable();

    private actionFn = new BehaviorSubject('');
    currentAction = this.actionFn.asObservable();
    constructor(private http: HttpClient) {
        if (localStorage.getItem('canonicalName') !== null) {
            this.canonicalName = localStorage.getItem('canonicalName') + '/';
        } else {
            this.canonicalName = '';
        }

    }



    getDBMassage(id: string) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Message/get-db-message/` + id);
    }

    getAPMassage(id: string) {
        return this.http.get<Result>(`${environment.appUrl}${this.canonicalName}Message/` + id);
    }
    changeMessage(mssgData) {
      this.messageSource.next(mssgData);
    }

    action(actionFn) {
        this.actionFn.next(actionFn);
      }


}
