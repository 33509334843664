import { Component, OnInit,Output,Input ,OnChanges} from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-upload-img-b64',
  templateUrl: './upload-img-b64.component.html',
  styleUrls: ['./upload-img-b64.component.scss']
})
export class UploadImgB64Component implements OnInit {
  @Output() someEvent = new EventEmitter();
  @Input() label:string;
  @Input() imgUrl:any;

  constructor() { }
  dragout(event){
    event.target.parentElement.classList.remove("dragover");
  }

  dragin(event){

    event.target.parentElement.classList.add("dragover");
  }
  ngOnInit() {
  }
  url1="";
  imgBase64="";
  handleFileSelect(evt){
    var files = evt.target.files;
    var file = files[0];
this.inputLabel=file.name;
  if (files && file) {
      var reader = new FileReader();
      var reader1 = new FileReader();

      reader.onload =this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
      if(this.url1==""){
        reader1.readAsDataURL(evt.target.files[0]); // read file as data url
        reader1.onload = (event:any) => { // called once readAsDataURL is completed
           this.url1 = event.target.result;
        }

      }
      if(this.url1!=""){
        reader1.readAsDataURL(evt.target.files[0]); // read file as data url
        reader1.onload = (event:any) => { // called once readAsDataURL is completed
           this.url1 = event.target.result;
        }

      }

  }
  
}
_handleReaderLoaded(readerEvt) {
  var binaryString = readerEvt.target.result;
         this.imgBase64=btoa(binaryString);
         this.getImg();
 }
 getImg(){
  var alldata={
    ImgBase64:this.imgBase64
  }
  this.someEvent.emit(this.imgBase64);
}
inputLabel="Choose file";
ngOnChanges(changes) {
  if(this.imgUrl){
    this.url1=this.imgUrl;
  }
}
}
